<template>
  <div class="ma-2">
    <v-container fluid v-resize="() => resizeTable('users')">
      <v-card>
        <v-card-title class="pb-0">
          {{ $t('Users') }}
          <v-spacer />

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click="
                  ($event) => {
                    isSearchPanelOpen = !isSearchPanelOpen;
                    resizeTable('users');
                    $event.target.blur();
                  }
                "
                >{{ isSearchPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon
              >
            </template>
            <div class="filter-tooltip">
              {{ isSearchPanelOpen ? $t('Hide filter') : $t('Show filter') }}
            </div>
          </v-tooltip>
        </v-card-title>

        <v-flex>
          <v-card-text>
            <div id="searchPanel" v-show="isSearchPanelOpen">
              <v-row>
                <v-col cols="4" md="4" xl="2"
                  ><gli-text-field
                    clearable
                    v-model="filter.username"
                    :label="$t('Username')"
                    single-line
                  ></gli-text-field
                ></v-col>
                <v-col cols="4" md="4" xl="2"
                  ><gli-text-field
                    clearable
                    v-model="filter.fullName"
                    :label="$t('Full name')"
                    single-line
                  ></gli-text-field
                ></v-col>
                <v-col cols="4" md="4" xl="2"
                  ><gli-text-field
                    clearable
                    v-model="filter.email"
                    :label="$t('Email')"
                    single-line
                  ></gli-text-field
                ></v-col>
                <v-col cols="4" md="4" xl="2"
                  ><gli-text-field
                    clearable
                    v-model="filter.role"
                    :label="$t('Role')"
                    single-line
                  ></gli-text-field
                ></v-col>
                <v-col cols="4" md="4" xl="2" v-if="isSuperAdmin"
                  ><gli-text-field
                    clearable
                    v-model="filter.organization"
                    :label="$t('Organization')"
                    single-line
                  ></gli-text-field
                ></v-col>
              </v-row>
            </div>

            <v-data-table
              id="users"
              :mobile-breakpoint="0"
              :headers="headers.map((item) => ({ ...item, text: $t(item.text) }))"
              fixed-header
              :items="filteredUsers"
              item-key="id"
              :options.sync="pagination"
            >
              <template v-slot:item.username="{ value }">
                <span>{{ value }}</span>
              </template>

              <template v-slot:item.fullName="{ value }">
                <span>{{ value }}</span>
              </template>

              <template v-slot:item.email="{ value }">
                <span>{{ value }}</span>
              </template>

              <template v-slot:item.role="{ value }">
                <span>{{ value }}</span>
              </template>

              <template v-slot:item.organization="{ value }">
                <span>{{ value }}</span>
              </template>

              <template v-slot:item.createdDate="{ value }">
                <span>{{ value }}</span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <gli-editor-button
                      class="ml-2"
                      color="primary"
                      fab
                      x-small
                      dark
                      v-on="on"
                      @click="edit(item._id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </gli-editor-button>
                  </template>
                  <span>{{ $t('Edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <gli-editor-button
                      class="ml-2"
                      color="error"
                      fab
                      x-small
                      dark
                      v-on="on"
                      @click="deleteUser(item._id)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </gli-editor-button>
                  </template>
                  <span>{{ $t('Delete') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-flex>
        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <gli-editor-button color="primary" @click="addNew">{{
            $t('New user')
          }}</gli-editor-button>
        </v-card-actions>
      </v-card>
      <UserDialog v-if="editedUser" />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-mini';
import { getDisplayFormatDateTime } from '../helpers/dateFormats';
import i18n from '../i18n';
import resizeTable from '../mixins/resizeTable';

const emptyFilter = {
  username: '',
  fullName: '',
  email: '',
  role: '',
  organization: ''
};

export default {
  name: 'Users',

  data() {
    return {
      headers: [
        {
          text: 'Username',
          value: 'username'
        },
        {
          text: 'Full name',
          value: 'fullName'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Role',
          value: 'role'
        },
        {
          text: 'Created',
          value: 'createdDate'
        },
        {
          text: '',
          value: 'actions',
          width: 125,
          sortable: false,
          align: 'end'
        }
      ],
      pagination: {
        page: 1
      },
      isSearchPanelOpen: true,
      filter: JSON.parse(JSON.stringify(emptyFilter))
    };
  },

  async mounted() {
    await this.getOrgs();
    await this.getAll();
    if (this.isSuperAdmin) {
      this.headers.splice(4, 0, { text: 'Organization', value: 'organization' });
    }
  },
  computed: {
    ...mapGetters('users', ['users', 'editedUser', 'isNew']),
    ...mapGetters('organization', ['organizations']),
    ...mapGetters('account', ['isSuperAdmin']),
    filteredUsers() {
      if (this.users) {
        let filtered = this.users.map((user) => ({
          ...user,
          fullName:
            (i18n.locale === 'hu' ? user.lastName || '' : user.firstName || '') +
            ' ' +
            (i18n.locale === 'hu' ? user.firstName || '' : user.lastName || ''),
          createdDate: moment(user.createdDate).format(getDisplayFormatDateTime()),
          organization: user.organizationId
            ? this.organizations.find((org) => org.id === user.organizationId).name
            : ''
        }));

        for (const [key, value] of Object.entries(this.filter)) {
          if (!value) {
            continue;
          }

          // gli-text-field filter
          const regexp = new RegExp(value, 'i');
          filtered = filtered.filter((item) => item[key].match(regexp));
        }
        return filtered;
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActions('users', ['getAll', 'addNew', 'edit']),
    ...mapActions('users', { deleteUser: 'delete' }),
    ...mapActions('organization', { getOrgs: 'getAll' })
  },

  mixins: [resizeTable],

  components: {
    UserDialog: () => import('../components/UserDialog')
  }
};
</script>
